import { RootState } from '@cls/redux'
import AppLoading from '@cls/spinner'
import { DraggablePanel } from '@kpv-lab/ui'
import React, { Suspense, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { useKeyPress } from '../../lib/hooks/key-press-hook'
import { selectEditingVersion } from '../../views/content/state/versions-redux'
import { MediaManager } from './MediaManager'
import { hideMediaPopup as hideMediaPopupAction } from './state/media-manager-popup-redux'

const mapStateToProps = (state: RootState) => {
  const { mediaPopupAttrs, visible, onClose, options } = state.mediaManagerPopup
  const { src, align, mediaId } = mediaPopupAttrs
  const version = selectEditingVersion(state)
  const mediaItems = version.mediaItems ?? {}

  return {
    options,
    visible,
    mediaItems,
    onClose,
    mediaAttrs: mediaId ? { ...mediaItems[mediaId], src, align, mediaId } : {},
  }
}

const connector = connect(mapStateToProps, {
  hideMediaPopup: hideMediaPopupAction,
})

type TypesFromRedux = ConnectedProps<typeof connector>

export function MediaManagerPopup({
  onClose,
  visible,
  hideMediaPopup,
  mediaAttrs,
  mediaItems,
  options,
}: TypesFromRedux) {
  const [uploadedMedia, setUploadedMedia] = useState(mediaAttrs)
  const onCloseHandler = () => {
    onClose && onClose(uploadedMedia, mediaItems)
    hideMediaPopup()
  }

  if (useKeyPress('Escape')) {
    onCloseHandler()
  }

  return (
    <div className={`panel-wrapper  ${visible ? '' : 'hidden'}`}>
      <DraggablePanel
        className={'ui-floating-panel media-manager-popup-view'}
        backdrop={true}
        backdropHandler={onCloseHandler}
      >
        <span className="draggable" />
        <Suspense fallback={<AppLoading />}>
          <MediaManager
            uploadedMedia={uploadedMedia}
            setUploadedMedia={setUploadedMedia}
            options={options}
          />
        </Suspense>
      </DraggablePanel>
    </div>
  )
}

export default connector(MediaManagerPopup)
