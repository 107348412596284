import {
  allowedAudioFilesTypes,
  allowedImageFilesTypes,
  allowedVideoFilesTypes,
  settings,
} from '@cls/config'
import { RootState } from '@cls/redux'
import {
  getHeightFromImgix,
  getImgixPath,
  processingOptions,
} from '@kpv-lab/image-utils'
import { selectEditingVersion } from '@kpv-lab/md-text-editor/src/utils/redux-helper'
import React, { useCallback, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { connect, ConnectedProps } from 'react-redux'

import { MediaItem } from '../multimedia-popup/components/MultimediaItem'
import AppLoading from '../spinner'
import { AudioForm } from './components/AudioForm'
import { ImageForm } from './components/ImageForm'
import { MediaGallery } from './components/MediaGallery'
import { VideoForm } from './components/VideoForm'
import { VideoUrlForm } from './components/VideoUrlForm'
import { requestUploadURL, uploadFileToS3 } from './uploadUtils'

export interface UploadedMedia {
  type: 'image' | 'audio' | 'video'
  align: string
  alt: string
  attribution: string
  credit: string
  description: string
  options: string
  path?: string
  src: string
  start: string
  preset: string
  _start: string
  isApproximateDate: boolean
  overwriteImageContent?: boolean
}

interface OwnProps {
  uploadedMedia: UploadedMedia
  setUploadedMedia: (media: UploadedMedia) => void
  options: any
}

const mapStateToProps = (state: RootState) => {
  const version = selectEditingVersion(state)
  const mediaItems = (version?.mediaItems || {}) as Record<string, MediaItem>
  return {
    mediaItems: Object.entries(mediaItems)
      .map(([, mediaItem]) => mediaItem)
      .filter((mediaItem) => mediaItem.path),
  }
}

const connector = connect(mapStateToProps, {})
type TypesFromRedux = ConnectedProps<typeof connector>
type AllProps = OwnProps & TypesFromRedux

export const getPath = (source: string | UploadedMedia) => {
  if (typeof source === 'string' || source instanceof String) {
    const pathParser = document.createElement('a')
    pathParser.href = source as string
    return pathParser.pathname.replace('/', '')
  }
  return source.path || source.src?.match(/images\/\w*\/\w*\.(\w*)/)?.[0] || ''
}

export const getHeightFromPath = (source: string | UploadedMedia) => {
  let urlStr
  if (typeof source === 'string' || source instanceof String) {
    urlStr = source as string
  } else {
    urlStr = (source as UploadedMedia).src
  }
  const url = new URL(urlStr.split('#')[0])
  const search = new URLSearchParams(url.search)
  return search.get('rh') || ''
}

const isImageSVG = (file) => {
  return file?.type === 'image/svg+xml' || file?.name?.split('.')[1] === 'svg'
}

let timeout: NodeJS.Timeout

const MediaManagerComponent = ({
  mediaItems,
  uploadedMedia,
  setUploadedMedia,
  options: managerOptions,
}: AllProps) => {
  const [uploadError, setUploadError] = useState<null | string>(null)
  const [fileLoading, setFileLoading] = useState(false)
  const [showGallery, setShowGallery] = useState(true)
  const [showVideoUrl, setShowVideoUrl] = useState(true)
  const mediaField = useRef<any>(null)
  const mediaForm = useRef<any>(null)

  const imageSize = uploadedMedia.src
    ? uploadedMedia.src.split('#')[1]
    : 'medium'

  const onDrop = useCallback(async (acceptedFiles) => {
    setFileLoading(true)

    // We can't ternary hide mediaForm as it needs to finish the loading state from the img onLoad.
    // Catch-22, can't show image until it's loaded, can't finish loading without showing image
    if (mediaForm.current) {
      mediaForm.current.style.display = 'none'
    }

    const uploadURL = await requestUploadURL(acceptedFiles[0])

    try {
      await uploadFileToS3(acceptedFiles[0], uploadURL.signedRequest)
      let uploadedMediaToSave = {
        ...uploadedMedia,
        src: uploadURL.url,
        path: uploadURL.url,
      }

      if (acceptedFiles[0].type.startsWith('image')) {
        const path = getPath(uploadURL.url)
        const fullPath = getImgixPath({
          path,
          assetHost: settings.assetHost,
        })

        clearTimeout(timeout)
        timeout = setTimeout(async () => {
          if (isImageSVG(acceptedFiles[0])) {
            uploadedMediaToSave = {
              ...uploadedMediaToSave,
              type: 'image',
              src: `${fullPath}#${imageSize}`,
              path,
            }
          } else {
            const height = await getHeightFromImgix({
              fullPath,
              width: imageSize,
            })
            uploadedMediaToSave = {
              ...uploadedMediaToSave,
              type: 'image',
              src: `${fullPath}rh=${height}#${imageSize}`,
              path,
            }
          }
          setUploadedMedia(uploadedMediaToSave)
        }, 2000)
        setUploadError(null)
        return
      } else if (acceptedFiles[0].type.startsWith('video')) {
        uploadedMediaToSave = { ...uploadedMediaToSave, type: 'video' }
      } else {
        uploadedMediaToSave = { ...uploadedMediaToSave, type: 'audio' }
      }

      clearTimeout(timeout)
      timeout = setTimeout(() => {
        setUploadedMedia(uploadedMediaToSave)
      }, 2000)
      setUploadError(null)
    } catch (e) {
      setUploadError('Media upload failed!, please try again')
      console.error(e)
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: [
      ...allowedAudioFilesTypes,
      ...allowedImageFilesTypes,
      ...allowedVideoFilesTypes,
    ].join(','),
  })

  const mediaLoaded = () => {
    mediaForm.current.style.display = 'flex'
    setFileLoading(false)
    setShowVideoUrl(false)
    setShowGallery(false)
  }

  // When the src for the audio control is first loaded, it returns a 403
  // - possibly because the item isn't immediately available
  const retryAudio = () => {
    console.error('Error fetching audio, trying again')

    setTimeout(() => {
      setUploadedMedia({
        ...uploadedMedia,
        src: uploadedMedia.src += `#${Math.floor(Math.random() * 10) + 1}`,
      })
    }, 2000)
  }

  const retryImgix = () => {
    console.error('Fetching imgix source failed. Busting cache and retrying...')
    mediaForm.current.style.display = 'none'

    /* imgix needs to fetch the image from the S3 bucket.
    If we hit the URL when it's not finished fetching, it will 404 and cache that
    response when we hit it again. To get around that we can
    bust the cache by appending a random number to the URL and try loading it again */

    setTimeout(() => {
      // add cache arguments before the hash sizing
      // [url + cacheBusting, imageSize]
      const currentSrc = mediaField.current.src.split('#')
      const path = currentSrc.split('?')[0]
      const height = getHeightFromPath(mediaField.current.src)
      setUploadedMedia({
        ...uploadedMedia,
        src: `${path}?${Math.floor(Math.random() * 10) + 1}&rh=${height}#${
          currentSrc[1]
        }`,
      })
    }, 2000)
  }

  const handleInputChange = (data: string, val: string) => {
    let newVal = val
    if (data === 'attribution' && val) {
      if (!/^(http|https):\/\//i.test(val)) {
        newVal = `http://${val}`
      }
    }

    setUploadedMedia({
      ...uploadedMedia,
      [data]: newVal,
    })
  }

  const dateAcceptHandler = (
    val: Record<string, string | number>,
    isApproximateDate: boolean
  ) => {
    const key = Object.keys(val)[0]
    const stringDate = val[key]
    const floatDate = val[`_${key}`]
    setUploadedMedia({
      ...uploadedMedia,
      [key]: stringDate,
      [`_${key}`]: floatDate,
      isApproximateDate,
    })
  }

  const imagePresetHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value: preset } = event.target
    const options =
      processingOptions[preset] || preset === ''
        ? preset
        : uploadedMedia.options

    // old pictures don't have a path property
    const path = getPath(uploadedMedia)
    const height = getHeightFromPath(uploadedMedia)
    const additionalParams = `rh=${height}`

    const fullPath = getImgixPath({
      path,
      options,
      additionalParams,
      assetHost: settings.assetHost,
    })

    setUploadedMedia({
      ...uploadedMedia,
      src: `${fullPath}#${imageSize}`,
      preset,
      options,
    })
  }

  const galleryClickHandler = (mediaItem: MediaItem) => {
    const {
      align,
      alt,
      attribution,
      credit,
      description,
      options,
      path,
      preset,
      start,
      _start,
      type,
      isApproximateDate,
    } = mediaItem
    if (!path) {
      console.error(`Media #${mediaItem.mediaId} missing path`)
      return
    }
    setShowGallery(false)
    setShowVideoUrl(false)
    setUploadedMedia({
      align: align || '',
      alt: alt || '',
      attribution: attribution || '',
      credit: credit || '',
      description: description || '',
      options: options || '',
      path: path || '',
      preset: preset || '',
      src: type === 'audio' ? path : `${settings.assetHost}${path}#medium`,
      start: start || '',
      _start: _start || '',
      type: type || 'image',
      isApproximateDate: isApproximateDate || false,
    })
  }

  const createVideoItem = (url: string) => {
    if (url) {
      mediaForm.current.style.display = 'flex'
      setFileLoading(false)
      setShowVideoUrl(false)
      setUploadedMedia({
        ...uploadedMedia,
        type: 'video',
        attribution: url,
        src: url,
      })
    }
  }

  // The Dropzone types cause a conflict, so I'm working around them for now.
  const rootProps: Record<string, any> = getRootProps()

  const contentStyle: React.CSSProperties =
    !uploadedMedia.src || !fileLoading ? { overflowY: 'auto' } : {}

  const isUploadingFile = fileLoading
  const isEditingVideo = uploadedMedia.type === 'video'

  return (
    <div className="media-manager" data-cy="media-manager">
      <h2>Media manager</h2>
      <div className="media-manager-content" style={contentStyle}>
        {!isEditingVideo && !isUploadingFile && (
          <div {...rootProps}>
            <input {...getInputProps({ multiple: false })} />
            <section
              className="image-drag-panel"
              data-cy="media-manager-drag-panel"
            >
              <p className="centered">{`${
                !isDragActive ? 'Drag &' : ''
              } drop media here to upload`}</p>
            </section>
          </div>
        )}
        {showVideoUrl && <VideoUrlForm onSubmit={createVideoItem} />}
        {/* The media gallery is currently disabled. See https://github.com/kpv-lab/cl-server/issues/1865 */}
        {false && showGallery && !mediaItems.length && (
          <div className="media-manager--no-gallery">No media uploaded yet</div>
        )}
        {false && showGallery && !!mediaItems.length && !uploadedMedia.src && (
          <>
            <h3 className="media-manager--header-small">
              Use a previously uploaded media
            </h3>
            <MediaGallery
              mediaItems={mediaItems}
              onClick={galleryClickHandler}
            />
          </>
        )}
        {uploadError ? <span>{uploadError}</span> : null}
        <div
          className="form-container"
          ref={mediaForm}
          data-cy="media-manager-form-container"
        >
          {uploadedMedia.type === 'audio' ? (
            <AudioForm
              dateAcceptHandler={dateAcceptHandler}
              uploadedMedia={uploadedMedia}
              handleInputChange={handleInputChange}
              handleOnCanPlayThrough={mediaLoaded}
              handleOnError={retryAudio}
            />
          ) : uploadedMedia.type === 'video' ? (
            <VideoForm
              dateAcceptHandler={dateAcceptHandler}
              handleInputChange={handleInputChange}
              handleOnLoad={mediaLoaded}
              videoData={uploadedMedia}
            />
          ) : (
            <ImageForm
              options={managerOptions}
              uploadedMedia={uploadedMedia}
              mediaFieldRef={mediaField}
              handleOnError={retryImgix}
              handleOnLoad={mediaLoaded}
              handleInputChange={handleInputChange}
              imagePresetHandler={imagePresetHandler}
              dateAcceptHandler={dateAcceptHandler}
            />
          )}
        </div>
      </div>

      {isUploadingFile ? <AppLoading /> : null}
    </div>
  )
}

const MediaManager = connector(MediaManagerComponent)

export { MediaManager, MediaManagerComponent }
