import { schema } from 'prosemirror-markdown'
import { Schema } from 'prosemirror-model'

const imageNodeSpec = {
  /* The current implementation to store image sizes/alignment is to
  put it in the url with hashes then parse it back out (in parser.js)
  ![alt text](https://url.com/image.png#medium##right "Logo Title Text 1") */
  ...schema.spec.nodes.get('image'),
  attrs: {
    ...schema.spec.nodes.get('image').attrs,
    align: { default: 'img-align-left' },
    mediaId: { default: null },
  },
  toDOM: function (node) {
    const { align } = node.attrs
    if (align && align.endsWith('left')) {
      return ['img', node.attrs]
    }
    return ['img', { ...node.attrs, class: `${align}` }]
  },
  parseDOM: [
    {
      tag: 'img[src]',
      getAttrs(dom) {
        return {
          mediaId: dom.getAttribute('mediaId'),
          align: dom.classList[0],
          src: dom.getAttribute('src'),
          title: dom.getAttribute('title'),
          alt: dom.getAttribute('alt'),
        }
      },
    },
  ],
}

const ParagraphNodeSpec = {
  attrs: {
    align: { default: null },
  },
  content: 'inline*',
  group: 'block',
  parseDOM: [
    {
      tag: 'p',
      getAttrs(dom) {
        const ALIGN_PATTERN = /.*(left|right|center|justify)/

        const { textAlign } = dom.style
        let align = textAlign || ''
        align = ALIGN_PATTERN.test(align) ? align : null
        return { align }
      },
    },
  ],
  toDOM(node) {
    const { align } = node.attrs

    // To save on spamming the database with the default left-aligned class, we return early in that
    // case.
    if (align && align.endsWith('left')) {
      return ['p', 0]
    }
    return ['p', { class: align }, 0]
  },
}

const updatedNodes = schema.spec.nodes
  .update('doc', {
    content: '(paragraph | block)+',
  })
  .update('paragraph', ParagraphNodeSpec)
  .update('image', imageNodeSpec)
  .append({
    info: {
      attrs: {
        href: {},
        insertEscape: {
          default: false,
        },
      },
      inline: true,
      group: 'inline',
      draggable: false,
      parseDOM: [{ tag: 'a', priority: 40 }],
      toDOM(node) {
        const { href } = node.attrs
        const properties = href.split('://')
        const className = properties[0]
        const dataInfoId = properties[1]

        return [
          'span',
          {
            class: className.split('_').join(' '),
            'data-info-id': dataInfoId,
          },
        ]
      },
    },
  })
  .append({
    reference: {
      attrs: {
        href: {},
        insertEscape: {
          default: false,
        },
      },
      inline: true,
      group: 'inline',
      draggable: false,
      parseDOM: [{ tag: 'a', priority: 40 }],
      toDOM(node) {
        const { href } = node.attrs
        const properties = href.split('/')

        const dataRefId = properties[2]
        const dataBlockId = properties[3]

        return [
          'span',
          {
            class: 'ref-block',
            'data-ref-id': dataRefId,
            'data-block-id': dataBlockId,
          },
        ]
      },
    },
  })
  .append({
    audio: {
      attrs: {
        src: '',
        mediaId: { default: null },
      },
      inline: true,
      group: 'inline',
      draggable: false,
      parseDOM: [
        {
          tag: 'a',
          priority: 40,
          getAttrs(dom) {
            return {
              mediaId: dom.getAttribute('mediaId'),
              src: dom.getAttribute('src'),
            }
          },
        },
      ],
      toDOM(node) {
        const { src, mediaId } = node.attrs

        return [
          'span',
          {
            src,
            mediaId,
            class: 'audio-block',
            title: 'Audio file: edit in Media Manager',
          },
        ]
      },
    },
    video: {
      attrs: {
        src: '',
        mediaId: { default: null },
      },
      inline: true,
      group: 'inline',
      draggable: false,
      parseDOM: [
        {
          tag: 'a',
          priority: 40,
          getAttrs(dom) {
            return {
              mediaId: dom.getAttribute('mediaId'),
              src: dom.getAttribute('src'),
            }
          },
        },
      ],
      toDOM(node) {
        const { src, mediaId } = node.attrs
        return [
          'span',
          {
            src,
            mediaId,
            class: 'video-block',
            title: 'Embedded video: edit in Media Manager',
          },
        ]
      },
    },
  })

const updatedMarks = schema.spec.marks.append({
  itemlink: {
    attrs: {
      href: {},
    },
    toDOM: (mark) => {
      const { href } = mark.attrs
      const properties = href.split('/')
      const templateId = properties[2]
      const parentId = properties[3]
      const dataId = properties[4]
      return [
        'span',
        {
          class: 'itemlink',
          'data-id': dataId,
          'data-templateid': templateId,
          'data-parentid': parentId === 'undefined' ? '' : parentId,
        },
      ]
    },
  },
  tag: {
    attrs: {
      href: {},
      title: { default: null },
    },
    toDOM: (mark) => {
      const { href } = mark.attrs
      if (href.startsWith('tag://')) {
        const properties = href.split('/')
        const tagType = properties[2]
        const tagId = properties[3]
        return [
          'span',
          {
            class: tagType,
            'data-tag-id': tagId,
          },
        ]
      }
    },
  },
  link: {
    ...schema.spec.marks.get('link'),
    attrs: {
      href: {},
      title: { default: null },
      target: { default: null },
    },
    inclusive: false,
    parseDOM: [
      {
        tag: 'a[href]',
        getAttrs: (dom) => {
          return {
            href: dom.getAttribute('href'),
          }
        },
      },
    ],
    toDOM: (node) => ['a', { ...node.attrs, target: '_blank' }, 0],
  },
})

/**
 * The marks line exists in order to make links higher priority than em's. This means we can have a
 * single, em'd link that spreads across lines without it being written out as several links.
 */
const markdownSchema = new Schema({
  nodes: updatedNodes,
  marks: updatedMarks
    .remove('link')
    .addBefore('em', 'link', updatedMarks.get('link')),
})

export { markdownSchema }
