import DateControl from '@kpv-lab/date-control'
import { Checkbox, Input } from '@kpv-lab/ui'
import React, { ChangeEvent, FC, MutableRefObject } from 'react'

import { ImageProcessingSelector } from '../../image-editor/components/ImageProcessingSelector'
import { UploadedMedia } from '../MediaManager'

interface ImageFormProps {
  uploadedMedia: UploadedMedia
  mediaFieldRef: MutableRefObject<HTMLImageElement>
  handleOnError: () => void
  handleOnLoad: () => void
  handleInputChange: (data: string, val: string) => void
  imagePresetHandler: (event: ChangeEvent<HTMLSelectElement>) => void
  dateAcceptHandler: (
    val: Record<string, string | number>,
    isApproximateDate: boolean
  ) => void
  options: any
}

export const ImageForm: FC<ImageFormProps> = ({
  uploadedMedia,
  mediaFieldRef,
  handleOnError,
  handleOnLoad,
  handleInputChange,
  imagePresetHandler,
  dateAcceptHandler,
  options,
}) => {
  return (
    <div className="image-form-outer">
      <div className="image-form-container">
        <div className="image-control-thumbnail">
          <div onClick={() => window.open(uploadedMedia.src, '_blank')}>
            <a
              href={`http://www.google.com/searchbyimage?image_url=${uploadedMedia.src}`}
              className="icon md-18 image-search"
              target="_clsSearch"
              onClick={(e) => e.stopPropagation()}
              title="Search Google for alternatives of this image"
            >
              search
            </a>
            <img
              ref={mediaFieldRef}
              onError={handleOnError}
              onLoad={handleOnLoad}
              src={uploadedMedia.src}
              className="image-control-picture centered"
            />
            <i className="material-icons md-18 icon-preview">visibility</i>
          </div>
        </div>
        <div className="item-field media-manager-controls">
          <Input
            structure={{
              type: 'text',
              options: { placeholder: 'Title', title: 'Title' },
            }}
            value={uploadedMedia.alt}
            updateHandler={handleInputChange}
            inline={false}
            dataKey="alt"
          />
          <Input
            structure={{
              type: 'textarea',
              options: {
                placeholder: 'Description',
                title: 'Description',
              },
            }}
            value={uploadedMedia.description}
            updateHandler={handleInputChange}
            inline={false}
            dataKey="description"
          />
          <Input
            structure={{
              type: 'text',
              options: {
                placeholder: 'Attribution',
                title: 'Attribution',
              },
            }}
            value={uploadedMedia.credit}
            updateHandler={handleInputChange}
            inline={false}
            dataKey="credit"
          />
          <Input
            structure={{
              type: 'text',
              options: { placeholder: 'Link', title: 'Link' },
            }}
            value={uploadedMedia.attribution}
            updateHandler={handleInputChange}
            inline={false}
            dataKey="attribution"
          />

          <ImageProcessingSelector
            val={uploadedMedia.options}
            preset={uploadedMedia.preset}
            onSelect={imagePresetHandler}
            onCustomValueChange={handleInputChange}
          />
          <DateControl
            structure={{
              showHMS: undefined,
              type: 'startDate',
              title: 'Date',
              showApproximateCheckbox: true,
              isApproximateDate: uploadedMedia.isApproximateDate,
            }}
            dateAcceptHandler={dateAcceptHandler}
            value={uploadedMedia.start}
            dataKey="start"
            placeholder="Date"
          />
        </div>
      </div>
      {options?.showOverwriteCheckbox && (
        <Checkbox
          structure={{
            label: 'Overwrite publication image',
          }}
          value={uploadedMedia.overwriteImageContent || false}
          dataKey="overwriteImageContent"
          updateHandler={handleInputChange}
        />
      )}
    </div>
  )
}
